<template>
  <div>
    <div class="sctp-container pad-tb10">
      <div class="pad-b10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/account/userbaseinfo' }">用户中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/requirement/myrelease' }">我发布的需求</el-breadcrumb-item>
          <template v-if="id">
            <el-breadcrumb-item>编辑需求</el-breadcrumb-item>
          </template>
          <template v-else>
            <el-breadcrumb-item>发布需求</el-breadcrumb-item>
          </template>
        </el-breadcrumb>
      </div>
      <el-row :gutter="10">
        <el-col :span="5">
          <div class="sctp-bg-white">
            <div class="pad-lr20 pad-tb15">
              <el-image
                style="width: 100%;height: 100%;"
                fit="cover"
                :src="previewImage"
              ></el-image>
            </div>
            <div class="pad-lr20" style="padding-bottom: 30px;text-align: center;">
              <div class="pad-b20 fz-16">发布需求流程</div>
              <el-steps style="height: 200px;" direction="vertical" :active="step">
                <el-step title="发布需求"></el-step>
                <el-step title="补充需求"></el-step>
                <el-step title="审核需求"></el-step>
                <el-step title="需求上架"></el-step>
              </el-steps>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="sctp-bg-white pad20">
            <el-form class="square-input-warp" ref="codeRequireForm" :model="codeRequireForm"
                     :rules="codeRequireFormRules"
                     label-width="150px">
              <h2 style="border-left: 5px solid #f90; padding-left: 10px;margin-bottom: 22px;">
                发布需求</h2>
              <el-form-item label="需求类型" prop="reqflag">
                <el-radio-group @change="$refs.reqType.resetField()"
                                v-model="codeRequireForm.reqflag">
                  <template v-for="item in requireTypeList">
                    <el-radio :key="item.type" :label="item.type" border>{{
                        item.reqname
                      }}
                    </el-radio>
                  </template>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="定制类型" prop="reqtypeid" ref="reqType">
                <el-select v-model="codeRequireForm.reqtypeid" filterable placeholder="请选择">
                  <el-option
                    v-for="item in sourceTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="需求标题" prop="title">
                <el-input class="w50" :maxlength="50" v-model="codeRequireForm.title">
                  <span slot="append">限50字以内</span>
                </el-input>
              </el-form-item>
              <el-form-item v-show="false" class="w50" label="预算金额(选填)" prop="budget">
                <el-input v-model.trim="codeRequireForm.budget"></el-input>
              </el-form-item>
              <el-form-item class="w50" label="预算金额(选填)" prop="budget_scope">
                <el-select v-model="codeRequireForm.budget_scope" placeholder="请选择（默认无预算）">
                  <el-option
                    v-for="item in budgetScopeList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                元
              </el-form-item>
              <el-form-item label="期望完成时间(选填)" prop="expectdate">
                <el-date-picker
                  v-model="codeRequireForm.expectdate"
                  type="date"
                  :picker-options="pickerOptions"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="需求描述" prop="reqdesc">
                <el-input type="textarea" :rows="6" maxlength="5000" placeholder="猜你喜欢：教育APP"
                          v-model="codeRequireForm.reqdesc"></el-input>
                <div style="top: -28px;right: 10px;line-height: 24px;" class="pa fc-info fz-12">{{
                    codeRequireForm.reqdesc && codeRequireForm.reqdesc.length || 0
                  }} 个字符
                </div>
                <div class="el-upload__tip">
                  注：需求描述请勿包含非法内容或隐私相关信息，以及QQ号、微信号、电话号码、邮箱等联系方式。最多5000个字符，详细需求可以在发布之后补充添加。
                </div>
              </el-form-item>
              <el-form-item style="width: 500px;" label="联系人" prop="contact">
                <el-input v-model="codeRequireForm.contact"></el-input>
              </el-form-item>
              <el-form-item style="width: 500px;" label="联系电话" prop="phone">
                <el-input :readonly="true" v-model="codeRequireForm.phone">
            <span
              @click="changePhoneDialogConfig.showChangePhoneDialog = true"
              slot="append">更换手机号</span>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="sctp-square" @click="onTemporaryStorageClick('codeRequireForm')"
                >
                  暂存
                </el-button>
                <el-button
                  class="sctp-square"
                  type="primary" @click="submitForm('codeRequireForm')"
                >
                  免费发布需求
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="更换手机号"
      :visible.sync="changePhoneDialogConfig.showChangePhoneDialog"
      width="30%"
      center>
      <el-form
        :ref="changePhoneDialogConfig.formRefName"
        :model="changePhoneForm"
      >
        <el-form-item
          prop="phone"
          :rules="[
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: validator.validPhone, message: '手机号格式不正确', trigger: 'blur' }
              ]"
        >
          <el-input
            placeholder="请输入手机号" v-model="changePhoneForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="code"
          :rules="[
              { required: true, message: '请输入验证码', trigger: 'blur' },
              { length: 6, message: '请输入六位', trigger: 'blur' },
            ]"
        >
          <el-input
            placeholder="请输入验证码" v-model="changePhoneForm.code"
          >
            <el-button
              :loading="!changePhoneDialogConfig.canClickSendBtn"
              @click="changePhoneDialogConfig.onSendCodeClick" slot="append"
            >
              <template v-if="changePhoneDialogConfig.canClickSendBtn">
                {{ changePhoneDialogConfig.sendCodeTipsText }}
              </template>
              <count-down v-else @finish="changePhoneDialogConfig.onCountDownFinish" format="ss秒后重试"
                          :time="60 * 1000"></count-down>
            </el-button>
          </el-input>
          <div ref="captcha" class="captcha"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary" @click="changePhoneDialogConfig.onSubmitClick"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import validator from '../../resources/js/async-validator'
import {captcha, config, demand} from "@/apis/index";
import {differenceInCalendarDays} from 'date-fns'

require('@ASSET/libs/jigsaw/jigsaw.min');
export default {
  components: {
    countDown: () => import('@CMP/common/CountDown')
  },
  props: ['id'],
  data() {
    return {
      previewImage: null,
      validator: validator,
      codeTypeList: [],
      requireTypeList: [],
      budgetScopeList: [],
      pickerOptions: {
        disabledDate: (date) => {
          let now = new Date();
          let curr = new Date(date)
          return differenceInCalendarDays(curr, now) < 1;
        }
      },
      changePhoneDialogConfig: {
        formRefName: 'changePhoneForm',
        showChangePhoneDialog: false,
        sendCodeTipsText: '获取验证码',
        canClickSendBtn: true,
        onSendCodeClick: () => {
          this.$refs[this.changePhoneDialogConfig.formRefName]
          .validateField('phone', res => {
            if (!res) {
              this.changePhoneDialogConfig.initCaptcha({
                onSuccess: this.changePhoneDialogConfig.doSendCode,
              });
              return
            }
            this.changePhoneDialogConfig.canClickSendBtn = true
          })
        },
        initCaptcha: ({onSuccess, onFail}) => {
          window.jigsaw.init({
            el: this.$refs.captcha,
            onSuccess: () => {
              this.$refs.captcha.innerHTML = '';
              if (onSuccess) {
                onSuccess();
              }
            },
            onFail: () => {
              if (onFail) {
                onFail();
              }
            },
            onRefresh: () => {
            }
          })
        },
        doSendCode: () => {
          this.changePhoneDialogConfig.canClickSendBtn = false;
          captcha.sendPhoneCode({
            phone: this.changePhoneForm.phone
          }).then(res => {
            this.$message.success('发送成功，请注意查收!')
          }).catch(res => {
            this.changePhoneDialogConfig.canClickSendBtn = true
          })
        },
        onSubmitClick: () => {
          this.$refs[this.changePhoneDialogConfig.formRefName]
          .validate(res => {
            if (res) {
              this.changePhoneDialogConfig.doCheckCode()
            }
          })
        },
        doCheckCode: () => {
          const {phone, code} = this.changePhoneForm;
          captcha.checkPhoneCode({
            phone: phone,
            code: code
          }).then(res => {
            this.codeRequireForm.phone = phone;
            this.changePhoneDialogConfig.showChangePhoneDialog = false;
            this.$message.success('更换成功!')
          })
        },
        onCountDownFinish: () => {
          this.changePhoneDialogConfig.canClickSendBtn = true;
          this.changePhoneDialogConfig.sendCodeTipsText = '重新获取'
        }
      },
      changePhoneForm: {
        phone: null,
        code: null
      },
      codeRequireForm: {
        reqflag: null,
        reqtypeid: null,
        title: null,
        budget: null,
        budget_scope: null,
        expectdate: null,
        reqdesc: '',
        phone: null,
        contact: null,
      },
      codeRequireFormRules: {
        reqflag: [
          {required: true, message: '软件类型非法', trigger: 'change'}
        ],
        reqtypeid: [
          {required: true, message: '定制类型非法', trigger: ['change', 'blur']}
        ],
        title: [
          {required: true, message: '需求标题不能为空', trigger: ['change', 'blur']}
        ],
        budget: [
          {
            trigger: ['change', 'blur'],
            validator: (rule, value, callback) => {
              value && isNaN(value) ? callback(new Error('预算金额非法')) : callback()
            }
          }
        ],
        expectdate: [
          {type: 'date', required: false, message: '期望完成时间非法', trigger: ['change', 'blur']}
        ],
        reqdesc: [
          {required: true, message: '需求描述非法', trigger: ['change', 'blur']}
        ],
        contact: [
          {required: true, message: '联系人不能为空', trigger: ['change', 'blur']}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: ['change', 'blur']},
          {validator: validator.validPhone, message: '手机号格式不正确', trigger: ['change', 'blur']}
        ]
      }
    }
  },
  methods: {
    onTemporaryStorageClick(formName) {
      const {codeRequireForm: formData, codeRequireFormRules} = this;
      let checkField = [];
      Object.keys(formData).forEach(key => {
        let value = formData[key];
        let needCheck = false;
        if (Array.isArray(value)) {
          if (value.length > 0) {
            needCheck = true;
          }
        } else if (value) {
          needCheck = true;
        }
        if (needCheck && codeRequireFormRules[key]) {
          checkField.push(key);
        }
      })
      if (checkField.length === 0) {
        this.doSubmitForm(true);
      } else {
        let hasError;
        let counter = 0;
        this.$refs[formName].validateField(checkField, (errorMessage => {
          ++counter;
          if (errorMessage) {
            if (!hasError) {
              hasError = true;
              this.$message.warning(errorMessage);
            }
          } else if (!hasError && counter === checkField.length) {
            this.doSubmitForm(true);
          }
        }));
      }
    },
    doSubmitForm(temp) {
      let formData = this.codeRequireForm;
      return this.$request.post({
        ...formData,
        temporary_storage: temp || false,
        userId: this.user.userId,
        reqcode: '1032'
      }).then(res => {
        this.$message.success('保存成功');
        this.$router.push('/requirement/myrelease');
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doSubmitForm();
        } else {
          this.$message.warning('请检查表单')
        }
      })
    },
    getDemandDetail() {
      if (!this.id) {
        return;
      }
      demand.requireUpdate({
        id: this.id,
        userId: this.user.userId,
      }).then(res => {
        const {sctpCodeRequire} = res.data;
        const codeRequireForm = {
          reqflag: sctpCodeRequire.reqflag,
          reqtypeid: sctpCodeRequire.reqtypeid,
          title: sctpCodeRequire.title,
          budget: sctpCodeRequire.budget,
          expectdate: sctpCodeRequire.expectdate,
          reqdesc: sctpCodeRequire.reqdesc,
          requireid: sctpCodeRequire.requireid,
          budget_scope: sctpCodeRequire.budget_scope,
          contact: sctpCodeRequire.contact,
        };
        if (sctpCodeRequire.phone) {
          codeRequireForm.phone = sctpCodeRequire.phone
        }
        this.codeRequireForm = codeRequireForm
      })
    },
    setDefaultPhone() {
      if (this.user) {
        if (this.user.phone) {
          this.codeRequireForm.phone = this.user.phone;
        }
        if (this.user.username || this.user.account) {
          this.codeRequireForm.contact = this.user.username || this.user.account;
        }
      }
    },
    getCodeTypeList() {
      demand.condition().then(res => {
        const {data = [], types = [], budgets = []} = res;
        this.codeTypeList = data;
        this.requireTypeList = types;
        this.budgetScopeList = budgets
      })
    },
    getPreviewImage() {
      config.loadConfig({key: 'REGISTER_PAGE_IMAGE'})
      .then(res => {
        this.previewImage = res.data;
      })
    },
  },
  computed: {
    sourceTypeOptions() {
      let options = [];
      if (this.codeTypeList && this.codeTypeList.length > 0 && this.codeRequireForm.reqflag) {
        const type = this.codeTypeList;
        const flag = this.codeRequireForm.reqflag;
        const group = {};
        type.map(item => {
          if (group[item.type]) {
            group[item.type].options.push({
              value: item.reqtypeid,
              label: item.typename
            })
          } else {
            group[item.type] = {
              options: [
                {
                  value: item.reqtypeid,
                  label: item.typename
                }
              ]
            }
          }
        });
        options = group[flag] && group[flag].options || [];
      }
      return options
    },
    step() {
      if (!this.id) {
        return 1;
      }
      return 2;
    }
  },
  beforeMount() {
    // 发布类似需求跳转
    let {params: {postSimilar}} = this.$route;
    if (postSimilar) {
      console.log(postSimilar);
      Object.keys(postSimilar).forEach(key => {
        this.codeRequireForm[key] = postSimilar[key];
      });
    }
    this.getCodeTypeList();
    this.setDefaultPhone();
    this.getPreviewImage();
    if (this.id) {
      this.getDemandDetail()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ {
  .el-form .w50 {
    width: 600px !important;
  }

  .product-edit .el-tabs__content {
    padding: 15px;
  }

  .el-select-group .el-select-dropdown__item {
    padding-left: 40px;
  }

  .captcha {
    position: absolute !important;
    bottom: 0;
    right: 0;
    background-color: #FFF;
    box-shadow: 0 0 10px 1px #ccc;
    z-index: 1;
  }
}


</style>
